@import '~@bryntum/scheduler/scheduler.stockholm.css';


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.switch-mode {
  line-height: 14px;
  font-size: 12px;
}

.switch-mode span {
  border-bottom:1px #000000 dashed;
  padding: 0 2px 0 0;
  display: inline-block;
}
.switch-mode span:hover {
  color: #e74415;
  cursor: pointer;
  border-bottom-color:#e74415;


}
.switch-mode span.active {
  border-bottom-color:rgba(0,0,0,0);
  font-weight: bold;
  color:#000;
  cursor: default;

}
.MuiButton-root.MuiButton-contained.MuiButton-containedSuccess,
.MuiLoadingButton-root.MuiButton-contained.MuiButton-containedPrimary{
  background-color: rgba(3, 144, 127);
  color:#fff;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary  {
  background-color: rgba(3, 144, 127);
  color:#fff;
}
.MuiButton-textPrimary.MuiButton-textPrimary {
  color: rgba(3, 144, 127);

}
.MuiSvgIcon-root.MuiStepIcon-root.Mui-active {
  color: rgba(3, 144, 127, 0.5);

}
.MuiSvgIcon-root.MuiStepIcon-root.Mui-completed {
  color: rgba(3, 144, 127, 1);

}
.MuiButtonBase-root.MuiButton-outlined.MuiButton-outlinedPrimary {
  border-color: rgba(3, 144, 127);
  color: rgba(3, 144, 127);

}
a.MuiLink-root {
  color:#ed6c02
}
a.MuiButton-root.MuiButton-contained.MuiLink-root {
  color:#fff
}
.MuiButton-root.MuiButton-contained.MuiButton-containedSecondary{
  color: rgba(0, 0, 0, 0.26);
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12);
}
.MuiButton-root.MuiButton-contained.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.menu-title {
  font-family: 'Hagrid' !important;
  font-size: 16px !important;
}

.c-json-content .c-json-key {
  color: #004488 !important;
}
.c-json-string {
  color: #618121 !important;;
}

.b-grid-panel-body{
  min-height: 800px;
}

.stream-card {
  margin: 0 -15px;
  width:100%
}
.stream-card.MuiBox-root {
  width: calc(100% * (1/4) - 20px);

  margin: 10px;
  padding: 0;
}

.droppable-field {
  width:100%
}

.stream-name{
  font-weight: bold;
  margin-bottom: 2px;
}

.error-helper-text-center {
  color: #d32f2f;
  margin: 3px 14px 0px 14px !important;
  font-size: 0.75rem !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.66 !important;
  letter-spacing: 0.03333em !important;
}
/*sx={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', maxWidth:'700px'}}*/

.campaign-types-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 700px;
}
.b-grid-header .b-sch-timerange.b-sch-line {
  background-color: #19345e!important;
}

.current-time-line {
  border-left-color: #19345e!important;
}
.current-time-line > label {
  background-color: #19345e!important;
  color: white;
}
.b-sch-event-content {
  color: black;
}

.b-sch-style-colored.b-sch-custom-color > .b-sch-event:not(.b-milestone):hover .b-sch-event-content {
  color:#000
}